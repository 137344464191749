.sectionContainer {
  @apply p-9 border-zur-primary border rounded-xl;
}

.sectionTitle {
  @apply text-2xl text-zur-primary font-bold uppercase text-center tracking-widest mb-8;
}

.sectionTitleLink {
  @apply no-underline;
}

.eventContainer {
  @apply grid grid-cols-3 gap-5 mt-6 first-of-type:mt-0;
}

.eventBullet {
  @apply text-zur-red-100;
}

.eventDate {
  @apply uppercase font-bold text-zur-blue-200;
}

.eventDateShort {
  @apply lg:hidden;
}

.eventDateLong {
  @apply hidden lg:inline;
}

.eventText {
  @apply col-span-2;
}

.eventLink {
  @apply uppercase no-underline tracking-wide;
}

.bundledWebinars {
  @apply list-decimal ml-4 mt-4;
}
