.mainWrapper {
  @apply border border-zur-primary rounded-xl my-9 p-4 lg:p-9;
}

.container {
  @apply grid grid-cols-12 p-4 lg:p-9;
}

.colText {
  @apply col-span-12 lg:col-span-7 text-zur-primary;
}

.title {
  @apply text-2xl font-bold mb-4;
}

.subTitle {
  @apply text-lg md:pr-10;
}

.linksContainer {
  @apply pt-10;
}

.linksItem {
  @apply pb-4 text-lg;
}

.colImg {
  @apply col-span-12 mt-4 lg:col-span-5 lg:mt-0 relative self-center text-right;
}
