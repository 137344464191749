.root {
  @apply flex;
}

.avatarImageContainer {
  @apply relative mr-4;
}

.avatarImageContainer.sm {
  @apply w-9 h-9;
}

.avatarImageContainer.lg {
  @apply w-20 h-20;
}

.avatarImage {
  @apply rounded-full;
}

.avatarInitials {
  @apply rounded-full bg-gray-200 text-center h-full text-3xl relative;
}

.avatarInitialsInner {
  @apply absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 text-gray-600;
}

.avatarText {
  @apply inline w-full;
}

.avatarText.sm {
  @apply text-base;
}

.avatarText.lg {
  @apply text-xl lg:text-2xl;
}

.authorLink {
  @apply ml-1 font-normal;
}
