.product {
  @apply flex items-center justify-between gap-4 border-zur-primary border rounded-2xl p-2 mt-10 flex-wrap md:flex-nowrap;
}

.homepageProduct {
  @apply p-16 gap-16;
}

.description {
  @apply p-6;
}

.homepageDescription {
  @apply p-0;
}

.productTitle {
  @apply text-2xl font-bold;

  > a {
    @apply no-underline;
  }
}

.info {
  @apply text-zur-blue-100 pt-4;
}

.price {
  @apply bg-zur-secondary-light rounded-lg text-zur-primary p-6 w-full md:w-auto;
}

.priceUSD {
  @apply text-3xl font-bold text-center;
}

.billingWording {
  @apply text-xl font-bold mt-2 text-center;
}

.learnMore {
  @apply mt-4;
}
