.eventWrapper {
  @apply grid grid-cols-12 gap-4;
}

.eventWrapperRows {
  @apply flex flex-col gap-4;
}

.eventContainer {
  @apply flex border-zur-primary border rounded-2xl p-8 col-span-12 lg:col-span-4;

  > a {
    @apply no-underline;
  }
}

.topRow {
  @apply flex items-center;
}

.eventImgContainer {
  @apply inline-flex rounded-full overflow-hidden;
}

.eventAvatarContainer {
  @apply pr-4;
}

.eventDate {
  @apply text-zur-red-100 font-bold text-lg;
}

.eventTime {
  @apply font-bold;
}

.bundledWebinars {
  @apply ml-2 mt-4 font-normal;
}

.bundledWebinar {
  @apply flex;
}

.bundledWebinarBullet {
  @apply text-zur-red-100 flex-shrink;
}

.bundledWebinarDate {
  @apply font-bold pl-2 min-w-[7ch];
}

.bundledWebinarTitle {
  @apply flex-grow pl-2;
}

.eventPresenter {
  @apply text-zur-primary italic text-base font-normal;
}

.eventInfoContainer {
  @apply text-zur-primary pt-4;
}

.eventTitle {
  @apply text-2xl font-bold;
}

.eventExcerpt {
  @apply font-normal;
}
