.ceReqsWrapper {
  @apply grid grid-cols-12;
}

.ceReqsSubWrapper {
  @apply col-span-12 lg:col-start-2 lg:col-span-10 lg:flex gap-8;
}

.ceReqsContent {
  @apply flex flex-col lg:w-8/12;
}

.ceReqsHeading {
  @apply text-2xl font-bold mb-8;
}

.ceReqsTokenLink {
  @apply relative inline-flex bg-zur-secondary-light text-zur-primary mr-2 mt-4 p-4 rounded;
}

.ceReqsProfessionLinks {
  @apply my-5;
}

.ceReqsProfessionLink {
  @apply mb-2;
}

.ceReqsCourseList {
  @apply mt-4;
}

.ceReqsCourseListItem {
  @apply mb-2;
}

.ceReqsSidebox {
  @apply lg:w-4/12 bg-gray-200 rounded-lg p-8;

  > p {
    @apply text-base;
    &:first-of-type {
      @apply mb-4;
    }
  }
}

.ceReqsFooter {
  @apply italic my-8;
}

/* CHECK CE REQUIREMENTS FORM */

.form {
  @apply w-full max-w-5xl lg:flex items-center mt-6;
}

.select,
.submit {
  @apply lg:mr-4;
}

.select > select {
  @apply border-zur-primary py-3 px-6 rounded-full text-base w-full lg:mb-0 mb-4;
}

.submit {
  @apply inline-flex items-center rounded-full py-3 px-7;
  line-height: inherit;
}

.arrow {
  @apply text-gray-300 ml-2;
}
