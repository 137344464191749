.sectionContainer {
  @apply py-9 text-center;
}

.sectionTitle {
  @apply text-2xl text-zur-primary font-bold;
}

.formGroup {
  @apply md:flex items-center mt-6 justify-center;

  > a {
    @apply no-underline;
  }
}

.button {
  @apply md:inline-flex md:w-max block w-full items-center rounded-full bg-zur-primary text-white py-3 px-7 mr-4 md:mb-0 mb-4;
}
