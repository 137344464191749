.eventContainer {
  @apply grid grid-cols-12 lg:gap-x-20;
}

.eventTextContainer {
  @apply col-span-12 lg:col-span-8;
}

.eventSidebarContainer {
  @apply col-span-12 lg:col-span-4;
}

.eventSidebar {
  @apply flex flex-col items-center;
  > p {
    @apply my-4 w-full;
  }
}

.eventVideo {
  @apply my-12 rounded-lg overflow-hidden;

  iframe {
    @apply w-full !important;
  }
}

.eventDate {
  @apply font-bold text-2xl;
}

.presenterInfo {
  @apply flex items-start gap-4 my-12;

  p {
    @apply italic first-of-type:mt-0;
  }
}

.bundledEventsContainer {
  @apply mt-8;
}

.resources {
  @apply mt-8;
}

.resourcesContainer {
  @apply mt-4;
}

.resourceLink {
  @apply lg:w-10/12 hover:underline no-underline text-zur-primary font-normal;
}

.contactInfo {
  @apply text-lg text-zur-gray-100;
}

.sidebarRegistered {
  @apply font-bold mt-3;
}

.sidebarRegisteredBundle {
  @apply font-bold mt-3 text-center;
}

.sidebarNotPurchasable {
  @apply text-center mt-3;
}

.sidebarJoinEvent {
  @apply flex flex-col mt-4;

  > a {
    @apply w-full;
  }
}

.marginTop {
  @apply mt-4;
}

.noWrap {
  @apply whitespace-nowrap;
}
