.heroContainer {
  @apply grid grid-cols-12 md:gap-12 gap-y-12 items-stretch;
}

.heroText {
  @apply col-span-12 md:col-span-7 text-zur-primary order-1 flex flex-col justify-between gap-4;
}

.heroTitle {
  @apply text-3xl md:text-4xl leading-snug md:leading-normal font-bold tracking-wide;
}

.heroContent {
  @apply flex gap-6 items-center;
}

.heroSubTitle {
  @apply text-xl md:text-2xl leading-snug;
}

.heroImage {
}

.heroSearch {
  @apply block md:flex items-center text-center;

  > a {
    @apply font-normal text-lg;
  }
}

.heroWebinars {
  @apply relative w-full h-full col-span-12 md:col-span-5 order-2;
}
