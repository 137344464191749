.sectionContainer {
  @apply text-center;
}

.sectionTitle {
  @apply text-2xl text-zur-primary font-bold;
}

.form {
  @apply flex justify-center;
}
